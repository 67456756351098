<template>
  <div class="chatbot-embed">
    <!-- prettier-ignore -->
    <base target="_parent">
    <!-- <h1>{{ name }}</h1> -->
    <div class="chatbot-container">
      <div class="chatbot-header" :style="getHeadingStyle">
        <!-- <div class="image-container">
          <span v-if="getImage">
            <img :src="getImage" alt="chatbot-image" class="centered-image" />
          </span>
        </div> -->

        <div>
          <!-- <h2 class="chatbot-heading" v-if="getHeading != ''">
            {{ getHeading }}
          </h2> -->
          <p>
            Chat with us!
            <span v-if="user && user['max_credit'] && user['max_credit'] >= 0">
              ({{ user['credit'] < 0 ? 0 : user['credit'] }}/{{
                user['max_credit']
              }})
            </span>
          </p>
        </div>

        <button class="chatbot-minimize" @click="onClose">
          <div></div>
        </button>
      </div>

      <div
        v-if="startChat"
        class="chatbot-conversation"
        ref="chatbotConversation"
      >
        <div class="chatbot-info">
          <div class="chatbot-avatar">
            <img src="/images/ape-chatbot.svg" />
            <div></div>
          </div>
          <div>
            <p class="chatbot-heading">{{ getHeading }}</p>
            <p class="chatbot-subheading">{{ getSubHeading }}</p>
          </div>
        </div>

        <div
          class="chatbot-message"
          v-for="(message, index) in messages"
          :key="index"
          :class="{
            'user-message': message.is_user,
            'bot-message': !message.is_user,
          }"
        >
          <!-- <div :style="getBotMessageStyle">
            {{ daysPassed(message) }}
          </div> -->
          <div
            class="message-user"
            :class="{ 'user-message': message.is_user }"
            v-if="
              showUser(message, index > 0 ? messages[index - 1] : undefined) &&
              !message.is_user
            "
          >
            <div class="chatbot-avatar small" v-if="!message.is_user">
              <img src="/images/ape-chatbot.svg" />
              <div></div>
            </div>
            <div v-if="!message.is_user">{{ getSubHeading }}</div>
            <div style="color: #667085" v-if="message.is_user">User</div>
          </div>

          <div
            class="chat-message"
            v-if="message.is_user"
            :style="getUserMessageStyle"
          >
            {{ message.text }}
            <div class="chat-message-time">{{ showTime(message) }}</div>
          </div>

          <!-- <div
            v-else
            :style="getBotMessageStyle"
            class="prose prose-sm prose-chatgpt break-words max-w-6xl"
            v-html="parseMarkdown(message.text)"
          /> -->
          <div v-else class="chat-message" :style="getBotMessageStyle">
            <div v-html="parseMarkdown(message.text)"></div>
            <div class="chat-message-time">{{ showTime(message) }}</div>
          </div>
        </div>
      </div>

      <div v-else class="chatbot-conversation">
        <div class="chatbot-intro">
          <div class="chatbot-ape">
            <img src="/images/ape-chatbot.svg" />
          </div>
          <p>
            Hello Nice to see you here! By pressing the "Start chat" button you
            agree to have your personal data processed as described in our
            Privacy Policy
          </p>
          <button @click="activeChat">Start live chat</button>
        </div>
      </div>

      <div v-if="!user || user['credit'] <= 0" class="chatbot-limit">
        <div class="chatbot-ape">
          <img src="/images/ape-chatbot.svg" />
        </div>
        <p>
          You've reached your free message limit. Become a
          <span style="color: #f26361; font-weight: 900">meal deal</span>
          to comfortably text and upgrade my limited features.
        </p>
        <button @click="upgradePlan">Upgrade now</button>
      </div>

      <div class="chatbot-input" v-if="startChat && user && user['credit'] > 0">
        <input
          type="text"
          v-model="userInput"
          @keydown.enter="sendMessage"
          :placeholder="getInputMessage"
        />
        <button
          @click="sendMessage"
          class="d-flex justify-content-center"
          :style="getSendButtonStyle"
        >
          <!-- <CIcon :icon="cilSend" /> -->
          <img
            :src="
              userInput != ''
                ? '/icons/message-send-active.svg'
                : '/icons/message-send.svg'
            "
          />
        </button>
      </div>
      <div class="chatbot-footer">
        Powered by <strong>&nbsp;Blocmates</strong>
      </div>
    </div>
  </div>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import { v4 as uuidv4 } from 'uuid'
import { cilSend } from '@coreui/icons'
import { marked } from 'marked'
import DOMPurify from 'isomorphic-dompurify'
import moment from 'moment'
// import hljs from 'highlight.js'

export default {
  props: {
    bot_id: {
      type: String,
      required: true,
    },
    conversation_id: {
      type: String,
      required: false,
    },
    style: {
      type: Object,
      default: () => ({
        botBackgroundColor: '#c7f0fa3d',
        botTextColor: '#667085',
        chatbotHeading: 'Chatbot',
        chatbotSubHeading: 'Support Agent',
        firstMessage: 'Hello Nice',
        headerBackgroundColor: '#1A5C7D',
        headerTextColor: '#FFFFFF',
        image: null,
        inputPlaceholderText: 'Write a message',
        sendButtonBackgroundColor: '#ffffff',
        userBackgroundColor: '#1A5C7D',
        userTextColor: '#FFFFFF',
        assistantName: 'Assistant',
      }),
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      startChat: true,
      messages: [],
      userInput: '',
      messageIndex: 0,
      conversationId: '',
      cilSend,
      localstyle: null,
      user: null,
      token: '',
      today: new Date(),
    }
  },
  async mounted() {
    console.log('loading..' + this.bot_id)
    window.addEventListener('message', this.onMessage)

    // try {
    //   console.log('get user..')
    //   const response = await HTTPService.getuser()
    //   this.user = response.data.user
    // } catch (error) {
    //   console.error(error)
    //   this.$emit('change-active-component', {
    //     component: 'Login',
    //   })
    // }

    await this.checkUser()
    await this.getLatestConversation()

    marked.setOptions({
      silent: true,
      xhtml: true,
      breaks: true,
      gfm: true,
    })

    // const renderer = {
    //   code(code, lang) {
    //     let language = 'plaintext'
    //     let highlightedCode
    //     try {
    //       highlightedCode = hljs.highlightAuto(code).value
    //     } catch {
    //       language = hljs.getLanguage(lang) ? lang : 'plaintext'
    //       highlightedCode = hljs.highlight(code, { language }).value
    //     }

    //     // ... handle the highlighted code as needed

    //     // For example, you can append it to an element or return it as a string
    //     // For appending to an element:
    //     const container = document.createElement('div')
    //     container.innerHTML = highlightedCode
    //     document.body.appendChild(container)

    //     // For returning as a string:
    //     return highlightedCode
    //   },
    // }

    // marked.use({ renderer })

    if (this.bot_id !== 'preview') {
      try {
        // const response = await HTTPService.get_style(this.bot_id)
        // console.log('finished loading bot settings')
        // console.log(response)
        // this.localstyle = response.data
      } catch (error) {
        console.error(error)
        // if there is an error default
        // this.localstyle = this.style
      }
    }

    console.log('add default mess', this.localstyle)
    if (!this.localstyle) {
      this.messages.push({
        text: this.style.firstMessage,
        is_user: false,
        created: Date.now() / 1000,
      })
      this.messageIndex = 1

      this.messages.push({
        text: 'Welcome to LiveChat I was made with. Pick a topic from the list or type down a question!',
        is_user: false,
        created: Date.now() / 1000,
      })
      this.messageIndex = 2
    } else {
      this.messages.push({
        text: this.localstyle.firstMessage,
        is_user: false,
      })
      this.messageIndex = 1
    }
    if (this.conversation_id && this.conversation_id != '') {
      this.conversationId = this.conversation_id
    }

    if (this.$route.query.conversationId) {
      this.conversationId = this.$route.query.conversationId
    }

    console.log('Already get conversationid', this.conversationId)

    if (!this.conversationId) {
      this.startChat = false
      this.conversationId = uuidv4()
    } else {
      this.startChat = true
      this.startConversation()
    }

    console.log('Loaded')
    window.parent.postMessage({ action: 'Loaded' }, '*')
  },
  unmounted() {
    window.removeEventListener('message', this.onMessage)
  },
  computed: {
    getImage() {
      if (!this.localstyle) {
        return this.style.image
      } else {
        return this.localstyle.image
      }
    },

    getAssistantName() {
      if (!this.localstyle) {
        return this.style.assistantName
      } else {
        return this.localstyle.assistantName
      }
    },
    getHeading() {
      if (!this.localstyle) {
        return this.style.chatbotHeading
      } else {
        return this.localstyle.chatbotHeading
      }
    },
    getSubHeading() {
      if (!this.localstyle) {
        return this.style.chatbotSubHeading
      } else {
        return this.localstyle.chatbotSubHeading
      }
    },
    getHeadingStyle() {
      // console.log(
      //   'this is headerBackgroundColor' + this.style.headerBackgroundColor,
      // )
      if (!this.localstyle) {
        return {
          backgroundColor: this.style.headerBackgroundColor,
          color: this.style.headerTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.headerBackgroundColor,
          color: this.localstyle.headerTextColor,
        }
      }
    },
    getSendButtonStyle() {
      if (!this.localstyle) {
        return {
          backgroundColor: this.style.sendButtonBackgroundColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.sendButtonBackgroundColor,
        }
      }
    },
    getInputMessage() {
      if (!this.localstyle) {
        return this.style.inputPlaceholderText
      } else {
        return this.localstyle.inputPlaceholderText
      }
    },
    getUserMessageStyle() {
      // console.log('this is getUserMessageStyle' + this.style.userTextColor)
      if (!this.localstyle) {
        return {
          backgroundColor: this.style.userBackgroundColor,
          color: this.style.userTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.userBackgroundColor,
          color: this.localstyle.userTextColor,
        }
      }
    },
    getBotMessageStyle() {
      // console.log('this is getBotMessageStyle' + this.style.botTextColor)

      if (!this.localstyle) {
        return {
          backgroundColor: this.style.botBackgroundColor,
          color: this.style.botTextColor,
        }
      } else {
        return {
          backgroundColor: this.localstyle.botBackgroundColor,
          color: this.localstyle.botTextColor,
        }
      }
    },
  },
  methods: {
    activeChat() {
      this.startChat = true
    },

    upgradePlan() {
      window.parent.postMessage({ action: 'Upgrade' }, '*')
    },

    async onMessage(event) {
      const currentToken = localStorage.getItem('token')
      const { token, size } = event.data
      console.log('token', token)
      if (token && currentToken != token) {
        if (token == '') localStorage.removeItem('token')
        else localStorage.setItem('token', token ?? '')
        await this.checkUser()
        await this.getLatestConversation()
        if (!this.conversationId) {
          this.conversationId = uuidv4()
          this.startChat = false
        } else {
          this.startChat = true
          this.startConversation()
        }
      }
      if (size) {
        const html = document.documentElement
        const width = size[0]
        if (size <= 479) {
          const fontsize = 0.7494769874476988 * 16 + 0.00836820083682 * width
          html.style.fontSize = fontsize + 'px'
        } else if (size <= 1440) {
          const fontsize = 0.812695109261186 * 16 + 0.00208116545265348 * width
          html.style.fontSize = fontsize + 'px'
        } else if (size <= 1920) {
          const fontsize = 0.5 * 16 + 0.0021666666666666674 * width
          html.style.fontSize = fontsize + 'px'
        } else {
          const fontsize = 0.5 * 16 + 0.0041666666666666663 * width
          html.style.fontSize = fontsize + 'px'
        }
      }
      // console.log('Received user data:', { token })
    },

    showTime(message) {
      return moment.utc(message.created).local().format('hh:mm A')
    },
    // showDate() {

    // }

    showUser(message, preMessage) {
      if (!preMessage) return true
      return message.is_user != preMessage.is_user
    },

    async checkUser() {
      try {
        const response = await HTTPService.getuser()
        this.user = response.data.user
        console.log('user', this.user)
      } catch (error) {
        console.log(error)
      }
    },

    async getLatestConversation() {
      try {
        console.log('get conversation history...')
        const response = await HTTPService.get_conversation_history(this.bot_id)
        if (response.data.length <= 0) return
        const id = response.data[0]['conversation_id']
        console.log('conversationId', id)
        this.conversationId = id
      } catch (error) {
        console.log(error)
      }
    },

    parseMarkdown(text) {
      if (typeof text !== 'string') {
        return '' // Return the text as is if it's not a string
      }

      text = text.replaceAll('\\n', '\n')
      text = text.replaceAll('```markdown', '```')
      try {
        text = text.replaceAll('```', '')
        let parsed = marked.parse(text)
        console.log('after marked.parse: ', text)

        // format Bing's source links more nicely
        // 1. replace "[^1^]" with "[1]" (during progress streams)
        parsed = parsed.replace(/\[\^(\d+)\^]/g, '<strong>[$1]</strong>')
        // 2. replace "^1^" with "[1]" (after the progress stream is done)
        parsed = parsed.replace(/\^(\d+)\^/g, '<strong>[$1]</strong>')
        console.log('after parsed: ', parsed)

        // Allow the iframe to show the images created by Bing Image Creator.
        return DOMPurify.sanitize(parsed, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'srcdoc',
          ],
        })
      } catch (err) {
        console.error('ERROR', err)
        return null
      }
    },
    async startConversation() {
      console.log('conversation ID', this.conversationId)
      try {
        const response = await HTTPService.get_conversation(this.conversationId)
        console.log(response.data)
        for (const message of response.data) {
          const text = message.text
          const is_user = message.is_user
          const created = message.created
          this.messages.push({ text, is_user, created })
        }
        if (this.messages.length > 1) {
          this.messages[0].created = this.messages[1].created
        }
      } catch (error) {
        console.log(error)
      }
    },

    async sendMessage() {
      if (!this.userInput) return
      if (this.user['credit'] <= 0) return
      this.addMessage(this.userInput, true)
      this.messageIndex = this.messageIndex + 1
      // URL encode user input and username to ensure they are safe to include in the URL
      const encodedUserInput = encodeURIComponent(this.userInput)
      const encodedUsername = encodeURIComponent(this.user.email)

      const url = `${process.env.VUE_APP_API_URL}/conversation?bot_id=${this.bot_id}&user_input=${encodedUserInput}&conversation_id=${this.conversationId}&username=${encodedUsername}`

      const eventSource = new EventSource(`${url}`)
      //initialize the message
      this.addMessage('', false)
      eventSource.onmessage = (event) => {
        this.user['credit'] -= 1
        if (event.data === '[DONE]') {
          console.log('DONE')
          this.messageIndex = this.messageIndex + 1
          eventSource.close()
        } else {
          // console.log('start add "' + event.data + '"')
          // console.log(event.data)
          this.addMessage(event.data, false)
          // console.log('endadd add')
        }
      }
      eventSource.onerror = (error) => {
        console.log('event', error.status, ':', error.message)
        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((json) => this.addMessage(json.message, false))
      }

      this.userInput = ''
    },
    addMessage(text, is_user) {
      if (this.messageIndex >= 0 && is_user === false) {
        // if first message then just push
        if (!this.messages[this.messageIndex]) {
          this.messages.push({
            text: '',
            is_user: is_user,
            created: Date.now(),
          })
        } else {
          // console.log('adding "' + text + '"')

          this.messages[this.messageIndex].text += text
          this.messages[this.messageIndex].is_user = is_user
        }
      } else {
        this.messageIndex = this.messages.length

        this.messages.push({
          text,
          is_user,
          created: Date.now(),
        })
        this.messageIndex = this.messages.length - 1
      }

      // Scroll to the bottom of the chatbot conversation
      this.$refs.chatbotConversation.scrollTop =
        this.$refs.chatbotConversation.scrollHeight
    },
    endConversation() {
      // this.$router.push('/chatbot-dashboard')
    },

    onClose() {
      window.parent.postMessage({ action: 'Close' }, '*')
    },
  },
}
</script>

<style>
/* html {
  font-size: 12px;
}

@media screen and (min-device-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-device-width: 1280px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-device-width: 2000px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-device-width: 3000px) {
  html {
    font-size: 20px;
  }
}

@media screen and (min-device-width: 4000px) {
  html {
    font-size: 24px;
  }
}

@media screen and (min-device-width: 5000px) {
  html {
    font-size: 28px;
  }
} */

html {
  width: 100vw;
  height: 100vh;
}

body,
#app {
  width: 100%;
  height: 100%;
  font-size: 0.75rem;
}

.chatbot-message {
  font-size: 0.875rem;
}

.bot-message div *:last-child {
  margin-bottom: 0 !important;
}
</style>

<style scoped>
/* @import '@/styles/blocmates-bot.scss'; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap');

input {
  font-size: 0.875rem;
}

.chatbot-embed {
  font-family: 'Poppins' !important;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* border: 1px solid #ccc;
  border-radius: 10px; */
  overflow: hidden;
}

.chatbot-conversation {
  flex: 1;
  padding: 0.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.chatbot-message {
  margin: 0.25rem 0;
}

.user-message {
  text-align: right;
  justify-items: flex-end;
}

.bot-message .chat-message {
  width: fit-content;
  text-align: left;
  background-color: #c7f0fa3d;
}

.chat-message * {
  width: 100%;
}

.chat-message .chat-message-time {
  font-size: 0.625rem;
  text-align: right;
  margin-top: 0.25rem;
}

.chatbot-message .chat-time {
  font-size: 0.75rem;
  padding: 0;
}

.chatbot-message > div {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  max-width: 80%;
}

.chatbot-message.user-message div {
  color: #fff;
}

.chatbot-input {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem 0 0.75rem;
  margin: 0 0.75rem 0.5rem;
  border: 1px solid #0000001a;
  border-radius: 1.125rem;
  overflow: hidden;
}

input[type='text'] {
  flex: 1;
  height: 100%;
  margin-right: 0.625rem;
  border-radius: 0.25rem;
  /* border: 1px solid #ccc; */
  line-height: normal;
  outline: none;
  border: none;
}

button {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  color: #fff;
  outline: none;
}

button img {
  height: 1rem;
}

button:hover {
  cursor: pointer;
  background-color: #0089b9;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  background-color: #1a5c7d;
  width: 100%;
  /* height: 70px; */
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-inline: 0.625rem;
  font-size: 1.25rem;
  margin-bottom: 0px;
  justify-content: space-between;
}

p {
  margin-bottom: 0px !important;
  font-size: 1rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}
.centered-image {
  max-width: 2.5rem;
  max-height: 2.5rem;
}

.chatbot-minimize {
  background-color: transparent;
  display: flex;
  height: 100%;
  padding: 0.75rem;

  > div {
    background-color: white;
    width: 0.875rem;
    height: 0.125rem;
    border-radius: 1px;
  }
}

.chatbot-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  height: 1.25rem;
  border-top: 1px solid #f6b3a6;
  color: #1a5c7d;
}

.chatbot-info {
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  gap: 1rem;
  padding: 0.75rem 0;
}

.chatbot-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  position: relative;
}

.chatbot-avatar.small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  position: relative;
  padding: 0.25rem;
}

.chatbot-avatar.small img {
  height: 1rem;
}

.chatbot-avatar div {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #268750;
  position: absolute;
  border-radius: 50%;
  left: 75%;
  top: 75%;
}

.chatbot-heading {
  line-height: 1.2;
}

.chatbot-subheading {
  font-size: 0.75rem;
  color: #667085;
}

.chatbot-intro {
  margin-top: 2.25rem;
  position: relative;
}

.chatbot-limit {
  position: relative;
}

.chatbot-intro,
.chatbot-limit {
  background-color: #c7f0fa3d;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2.25rem 0 1rem;
}

.chatbot-intro .chatbot-ape,
.chatbot-limit .chatbot-ape {
  position: absolute;
  top: -1.5rem;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 2.75rem;
  background-color: white;
}

.chatbot-ape img {
  /* width: 40px; */
  height: 100%;
}

.chatbot-intro p,
.chatbot-limit p {
  width: 70%;
  text-align: center;
  color: #667085;
}

.chatbot-intro button,
.chatbot-limit button {
  font-size: 0.875rem;
  width: 50%;
  height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #f26361;
}

.chatbot-intro button:hover,
.chatbot-limit button:hover {
  background-color: #da4946;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a5c7d;
  border-radius: 0.25rem;
}

.message-user {
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.625rem;
  color: #667085;
}
</style>
